export const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    const currentUrl = new URL(import.meta.url);
    const firebaseScriptUrl = new URL("firebase-messaging-sw.js", currentUrl.origin);

    navigator.serviceWorker
      .register(firebaseScriptUrl)
      .then(function (registration) {
        return registration.scope;
      })
      .catch(function (err) {
        return err;
      });
  }
};
