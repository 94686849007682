import { registerServiceWorker } from "~src/serviceWorker";
import { isElectron } from "~src/utils/electronUtils";

async function loadConfig() {
  try {
    const response = await fetch("/config/appConfig.json", {
      cache: "no-cache",
    });
    if (!response.ok) {
      throw new Error(`Failed to get config with code: ${response.status} status: ${response.statusText}`);
    }

    const configJSON = await response.json();
    if (window.location.hostname == `portal.${configJSON.CLUSTER_URL}`) {
      configJSON.API_BASE_URL = `https://api.${configJSON.CLUSTER_URL}`;
      configJSON.API_WEBSOCKET_URL = `wss://api.${configJSON.CLUSTER_URL}/cable`;
      configJSON.CALLER_URL = `https://live.${configJSON.CLUSTER_URL}`;
      configJSON.LIVEKIT_WEBSOCKET_URL = `wss://webrtc.${configJSON.CLUSTER_URL}`;
    }

    window.__PREPARED_CONFIG__ = configJSON;

    return configJSON;
  } catch (e) {
    window.__CONFIG_ERROR__ = e;
  }
}

async function main() {
  if (!isElectron()) registerServiceWorker();
  try {
    const config = await loadConfig();
    const startup = await import("./startup");

    await startup.default(config);
  } catch (e) {
    window.__STARTUP_ERROR__ = e;
  }
}

main();
